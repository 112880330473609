import * as actions from "./actionTypes";
import { toast } from "react-toastify";

const initialState = {
	loading: false,
	allEmployers: {},
	allEmployersBalance: {},
	allEmployersCountloading: false,
	allEmployersCount: {},

	allEmployerAdminLoading: false,
	allEmployerAdmin: {},
	downloadAllEmployerAdminLoading: false,
	downloadAllEmployerAdmin: {},

	downloadLoading: false,
	downloadAllEmployers: [],
	getEmployer: {},
	getEmployerLoading: false,

	employerTokenLoading: false,
	employerToken: {},

	employerApproveLoading: false,
	employerActivateLoading: false,
	employerDeactivateLoading: false,

	employerEmployeeLoading: false,
	allEmployerEmployee: {},
	acceptedEmployerEmployeeLoading: false,
	acceptedEmployerEmployee: {},
	downloadEmployerEmployeeLoading: false,
	downloadAllEmployerEmployee: {},

	employeeActivateLoading: false,
	employeeDeactivateLoading: false,
	employeeStopWithdrawalsLoading: false,
	employeeResumeWithdrawalsLoading: false,

	generatedERALoading: false,
	generatedERA: {},
	reloadPage: false,

	isRolloverLoading: false,
	isEmpEarningsModalOpen: false,
	isAutoWdlLoading: false,
	isSetEarningsLoading: false,
	
	rejectEmployerOnboardingLoading: false,
	rejectEmployerOnboardingData: {},
	
	selectedEmployer: {},
	employerTransactionsLoading: false,
	employerTransactionsData: {},
	
	employerTransactionsMetricsLoading: false,
	employerTransactionsMetricsData: {},
	
	downloadEmployerTransactionsLoading: false,
	downloadEmployerTransactionsData: {},

	approveOdpLoading: false,
	approveOdpData: {},

	rejectOdpLoading: false,
	rejectOdpData: {},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {

		case actions.REJECT_EMPLOYER_ODP_START:
			state = {
				...state,
				rejectOdpLoading: true,
				rejectOdpData: {},
			};
			return state;

		case actions.REJECT_EMPLOYER_ODP_DONE:
			toast.success(action.payload.message);
			state = {
				...state,
				rejectOdpLoading: false,
				rejectOdpData: action.payload,
			};
			return state;

		case actions.REJECT_EMPLOYER_ODP_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				rejectOdpLoading: false,
				rejectOdpData: {},
			};
			return state;

		case actions.APPROVE_EMPLOYER_ODP_START:
			state = {
				...state,
				approveOdpLoading: true,
				approveOdpData: {},
			};
			return state;

		case actions.APPROVE_EMPLOYER_ODP_DONE:
			toast.success(action.payload.message);
			state = {
				...state,
				approveOdpLoading: false,
				approveOdpData: action.payload,
			};
			return state;

		case actions.APPROVE_EMPLOYER_ODP_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				approveOdpLoading: false,
				approveOdpData: {},
			};
			return state;

		case actions.TOGGLE_AUTO_WDL_START:
			state = {
				...state,
				isAutoWdlLoading: true,
			};
			return state;

		case actions.TOGGLE_AUTO_WDL_DONE:
			toast.success(action.payload.message);
			setTimeout(() => window.location.reload(), 1500);
			state = {
				...state,
				isAutoWdlLoading: false,
			};
			return state;

		case actions.TOGGLE_AUTO_WDL_FAILED:
			state = {
				...state,
				isAutoWdlLoading: false,
			};
			return state;

		case actions.SET_EARNINGS_START:
			state = {
				...state,
				isSetEarningsLoading: true,
			};
			return state;

		case actions.SET_EARNINGS_DONE:
			toast.success(action.payload.message);
			state = {
				...state,
				isSetEarningsLoading: false,
			};
			return state;

		case actions.SET_EARNINGS_FAILED:
			state = {
				...state,
				isSetEarningsLoading: false,
			};
			return state;

		case actions.TOGGLE_EMP_EARNINGS_MODAL:
			state = {
				...state,
				isEmpEarningsModalOpen: !state.isEmpEarningsModalOpen,
			};
			return state;

		case actions.TOGGLE_ROLLOVER_START:
			state = {
				...state,
				isRolloverLoading: true,
			};
			return state;

		case actions.TOGGLE_ROLLOVER_DONE:
			setTimeout(() => window.location.reload(), 2000);
			state = {
				...state,
				isRolloverLoading: false,
			};
			return state;

		case actions.TOGGLE_ROLLOVER_FAILED:
			state = {
				...state,
				isRolloverLoading: false,
			};
			return state;

		case actions.GET_ALL_EMPLOYERS:
			state = {
				...state,
				loading: false,
				allEmployers: { ...action.payload },
			};
			return state;

		case actions.GET_ALL_EMPLOYERS_START:
			state = {
				...state,
				loading: true,
				allEmployers: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYERS_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				loading: false,
				allEmployers: {},
			};
			return state;
		case actions.GET_ALL_EMPLOYERS_BALANCE:
			state = {
				...state,
				loading: false,
				allEmployersBalance: { ...action.payload },
			};
			return state;

		case actions.GET_ALL_EMPLOYERS_BALANCE_START:
			state = {
				...state,
				loading: true,
				allEmployersBalance: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYERS_BALANCE_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				loading: false,
				allEmployersBalance: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYER_ADMIN:
			state = {
				...state,
				allEmployerAdminLoading: false,
				allEmployerAdmin: { ...action.payload.data },
			};
			return state;

		case actions.GET_ALL_EMPLOYER_ADMIN_START:
			state = {
				...state,
				allEmployerAdminLoading: true,
				allEmployerAdmin: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYER_ADMIN_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				allEmployerAdminLoading: false,
				allEmployerAdmin: {},
			};
			return state;

		case actions.DOWNLOAD_EMPLOYER_ADMIN:
			state = {
				...state,
				downloadAllEmployerAdminLoading: false,
				downloadAllEmployerAdmin: { ...action.payload.data },
			};
			return state;

		case actions.DOWNLOAD_EMPLOYER_ADMIN_START:
			state = {
				...state,
				downloadAllEmployerAdminLoading: true,
				downloadAllEmployerAdmin: {},
			};
			return state;

		case actions.DOWNLOAD_EMPLOYER_ADMIN_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				downloadAllEmployerAdminLoading: false,
				downloadAllEmployerAdmin: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYERS_COUNT:
			state = {
				...state,
				allEmployersCountloading: false,
				allEmployersCount: { ...action.payload.data },
			};
			return state;

		case actions.GET_ALL_EMPLOYERS_COUNT_START:
			state = {
				...state,
				allEmployersCountloading: true,
				allEmployersCount: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYERS_COUNT_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				allEmployersCountloading: false,
				allEmployersCount: {},
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_EMPLOYERS:
			state = {
				...state,
				downloadLoading: false,
				// downloadAllEmployers: { ...action.payload.employers },
				downloadAllEmployers: action?.payload?.data?.employers ?? [],
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_EMPLOYERS_START:
			toast("Downloading Data...");
			state = {
				...state,
				downloadLoading: true,
				downloadAllEmployers: [],
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_EMPLOYERS_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				downloadLoading: false,
				downloadAllEmployers: [],
			};
			return state;

		case actions.GET_EMPLOYER:
			state = {
				...state,
				getEmployerLoading: false,
				getEmployer: {
					employer: action.payload.employer,
					companyInfo: action.payload.companyInfo,
				},
			};
			return state;

		case actions.GET_EMPLOYER_START:
			state = {
				...state,
				getEmployerLoading: true,
				getEmployer: {},
			};
			return state;

		case actions.GET_EMPLOYER_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				getEmployerLoading: false,
				getEmployer: {},
			};
			return state;

		case actions.SELECT_EMPLOYER:
			state = {
				...state,
				selectedEmployer: action.payload,
			};
			return state;

		case actions.GET_EMPLOYER_TRANSACTIONS_START:
			state = {
				...state,
				employerTransactionsLoading: true,
				employerTransactionsData: {},
			};
			return state;

		case actions.GET_EMPLOYER_TRANSACTIONS_DONE:
			state = {
				...state,
				employerTransactionsLoading: false,
				employerTransactionsData: action.payload,
			};
			return state;

		case actions.GET_EMPLOYER_TRANSACTIONS_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employerTransactionsLoading: false,
				employerTransactionsData: {},
			};
			return state;

		case actions.GET_EMPLOYER_TRANSACTIONS_METRICS_START:
			state = {
				...state,
				employerTransactionsMetricsLoading: true,
				employerTransactionsMetricsData: {},
			};
			return state;

		case actions.GET_EMPLOYER_TRANSACTIONS_METRICS_DONE:
			state = {
				...state,
				employerTransactionsMetricsLoading: false,
				employerTransactionsMetricsData: action.payload,
			};
			return state;

		case actions.GET_EMPLOYER_TRANSACTIONS_METRICS_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employerTransactionsMetricsLoading: false,
				employerTransactionsMetricsData: {},
			};
			return state;

		case actions.DOWNLOAD_EMPLOYER_TRANSACTIONS_START:
			state = {
				...state,
				downloadEmployerTransactionsLoading: true,
				downloadEmployerTransactionsData: {},
			};
			return state;

		case actions.DOWNLOAD_EMPLOYER_TRANSACTIONS_DONE:
			state = {
				...state,
				downloadEmployerTransactionsLoading: false,
				downloadEmployerTransactionsData: action.payload,
			};
			return state;

		case actions.DOWNLOAD_EMPLOYER_TRANSACTIONS_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				downloadEmployerTransactionsLoading: false,
				downloadEmployerTransactionsData: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYER_EMPLOYER:
			state = {
				...state,
				employerEmployeeLoading: false,
				allEmployerEmployee: { ...action.payload.employees },
			};
			return state;

		case actions.GET_ALL_EMPLOYER_EMPLOYER_START:
			state = {
				...state,
				employerEmployeeLoading: true,
				allEmployerEmployee: {},
			};
			return state;

		case actions.GET_ALL_EMPLOYER_EMPLOYER_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employerEmployeeLoading: false,
				allEmployerEmployee: {},
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER:
			state = {
				...state,
				downloadEmployerEmployeeLoading: false,
				downloadAllEmployerEmployee: { ...action.payload.employees },
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER_START:
			toast("Downloading Data...");
			state = {
				...state,
				downloadEmployerEmployeeLoading: true,
				downloadAllEmployerEmployee: {},
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				downloadEmployerEmployeeLoading: false,
				downloadAllEmployerEmployee: {},
			};
			return state;

		case actions.GET_ACCEPTED_EMPLOYER_EMPLOYER:
			state = {
				...state,
				acceptedEmployerEmployeeLoading: false,
				acceptedEmployerEmployee: { ...action.payload.employees },
			};
			return state;

		case actions.GET_ACCEPTED_EMPLOYER_EMPLOYER_START:
			state = {
				...state,
				acceptedEmployerEmployeeLoading: true,
				acceptedEmployerEmployee: {},
			};
			return state;

		case actions.GET_ACCEPTED_EMPLOYER_EMPLOYER_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				acceptedEmployerEmployeeLoading: false,
				acceptedEmployerEmployee: {},
			};
			return state;

		case actions.RESEND_INVITE:
			toast.success("Mail Sent");
			state = {
				...state,
			};
			return state;

		/*
        case actions.RESEND_INVITE_START:
            toast("Resending invite");
            state = {
                ...state,
            }
            return state;
        */

		case actions.RESEND_INVITE_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
			};
			return state;

		case actions.RESEND_UNENROLLED_EMPLOYEES_INVITE:
			toast.success("Invites sent");
			state = {
				...state,
			};
			return state;

		/*
        case actions.RESEND_UNENROLLED_EMPLOYEES_INVITE_START:
            toast("Resending invite");
            state = {
                ...state,
            }
            return state;
        */

		case actions.RESEND_UNENROLLED_EMPLOYEES_INVITE_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
			};
			return state;

		case actions.GET_EMPLOYER_TOKEN:
			state = {
				...state,
				employerTokenLoading: false,
				employerToken: { ...action.payload },
			};
			return state;

		case actions.GET_EMPLOYER_TOKEN_START:
			state = {
				...state,
				employerTokenLoading: true,
				employerToken: {},
			};
			return state;

		case actions.GET_EMPLOYER_TOKEN_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employerTokenLoading: false,
				employerToken: {},
			};
			return state;

		case actions.APPROVE_EMPLOYER:
			toast.success("Employer Approved");
			// toast.success(action.payload.message);
			state = {
				...state,
				employerApproveLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.APPROVE_EMPLOYER_START:
			toast("Approving Employer");
			state = {
				...state,
				employerApproveLoading: true,
			};
			return state;

		case actions.APPROVE_EMPLOYER_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employerApproveLoading: false,
			};
			return state;

		case actions.REJECT_EMPLOYER_ONBOARDING_START:
			state = {
				...state,
				rejectEmployerOnboardingLoading: true,
				rejectEmployerOnboardingData: {},
			};
			return state;

		case actions.REJECT_EMPLOYER_ONBOARDING_DONE:
			toast.success(action.payload.message);
			state = {
				...state,
				rejectEmployerOnboardingLoading: false,
				rejectEmployerOnboardingData: action.payload,
			};
			return state;

		case actions.REJECT_EMPLOYER_ONBOARDING_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				rejectEmployerOnboardingLoading: false,
				rejectEmployerOnboardingData: {},
			};
			return state;

		case actions.RESET_ONBOARDING_REJECTION_RESPONSE:
			state = {
				...state,
				rejectEmployerOnboardingData: {},
			};
			return state;

		case actions.ENABLE_EMPLOYER_EMPLOYEE:
			toast.success("Employee Activated");
			// toast.success(action.payload.message);
			state = {
				...state,
				employeeActivateLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.ENABLE_EMPLOYER_EMPLOYEE_START:
			toast("Activating Employee");
			state = {
				...state,
				employeeActivateLoading: true,
			};
			return state;

		case actions.ENABLE_EMPLOYER_EMPLOYEE_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employeeActivateLoading: false,
			};
			return state;

		case actions.DISABLE_EMPLOYER_EMPLOYEE:
			toast.success("Employee Deactivated");
			// toast.success(action.payload.message);
			state = {
				...state,
				employeeDeactivateLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.DISABLE_EMPLOYER_EMPLOYEE_START:
			toast("Deactivating Employee");
			state = {
				...state,
				employeeDeactivateLoading: true,
			};
			return state;

		case actions.DISABLE_EMPLOYER_EMPLOYEE_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employeeDeactivateLoading: false,
			};
			return state;

		case actions.ENABLE_EMPLOYER:
			toast.success("Employer Activated");
			// toast.success(action.payload.message);
			state = {
				...state,
				employerActivateLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.ENABLE_EMPLOYER_START:
			toast("Activating Employer");
			state = {
				...state,
				employerActivateLoading: true,
			};
			return state;

		case actions.ENABLE_EMPLOYER_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employerActivateLoading: false,
			};
			return state;

		case actions.DISABLE_EMPLOYER:
			toast.success("Employer Deactivated");
			// toast.success(action.payload.message);
			state = {
				...state,
				employerDeactivateLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.DISABLE_EMPLOYER_START:
			toast("Deactivating Employer");
			state = {
				...state,
				employerDeactivateLoading: true,
			};
			return state;

		case actions.DISABLE_EMPLOYER_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employerDeactivateLoading: false,
			};
			return state;

		case actions.STOP_EMPLOYEE_WITHDRAWALS:
			toast.success("Employee withdrawals stopped");
			// toast.success(action.payload.message);
			state = {
				...state,
				employeeStopWithdrawalsLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.STOP_EMPLOYEE_WITHDRAWALS_START:
			toast("Stopping all employee withdrawals for this employer");
			state = {
				...state,
				employeeStopWithdrawalsLoading: true,
			};
			return state;

		case actions.STOP_EMPLOYEE_WITHDRAWALS_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employeeStopWithdrawalsLoading: false,
			};
			return state;

		case actions.RESUME_EMPLOYEE_WITHDRAWALS:
			toast.success("Employee withdrawals resumed");
			// toast.success(action.payload.message);
			state = {
				...state,
				employeeResumeWithdrawalsLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.RESUME_EMPLOYEE_WITHDRAWALS_START:
			toast("Resuming all employee withdrawals for this employer");
			state = {
				...state,
				employeeResumeWithdrawalsLoading: true,
			};
			return state;

		case actions.RESUME_EMPLOYEE_WITHDRAWALS_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				employeeResumeWithdrawalsLoading: false,
			};
			return state;

		case actions.GET_PERFORMANCE_EMPLOYEES:
			state = {
				...state,
				loadingPerformanceEmployees: false,
				performanceEmployees: { ...action.payload },
			};
			return state;

		case actions.GET_PERFORMANCE_EMPLOYEES_START:
			state = {
				...state,
				loadingPerformanceEmployees: true,
			};
			return state;

		case actions.GET_PERFORMANCE_EMPLOYEES_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				loadingPerformanceEmployees: false,
				performanceEmployees: {},
			};
			return state;

		case actions.GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS:
			state = {
				...state,
				loadingPerformanceEmployeesWithdrawals: false,
				performanceEmployeesWithdrawals: { ...action.payload },
			};
			return state;

		case actions.GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS_START:
			state = {
				...state,
				loadingDashboardPerformanceRepayment: true,
			};
			return state;

		case actions.GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				loadingPerformanceEmployeesWithdrawals: false,
				performanceEmployeesWithdrawals: {},
			};
			return state;

		case actions.GENERATE_ERA:
			toast.success("Reconciliation Account Generated");
			state = {
				...state,
				generatedERALoading: false,
				generatedERA: action.payload,
			};
			return state;

		case actions.GENERATE_ERA_START:
			state = {
				...state,
				generatedERALoading: true,
				generatedERA: {},
			};
			return state;

		case actions.GENERATE_ERA_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				generatedERALoading: false,
				generatedERA: {},
			};
			return state;

		case actions.RELOAD_PAGE_RESET:
			state = {
				...state,
				reloadPage: false,
			};
			return state;

		default:
			return state;
	}
}
