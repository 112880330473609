import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import DateFormat from "utils/dateFormat";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as PdfPlaceholder } from "assets/images/icons/project-icons/pdf-placeholder.svg";
import { ReactComponent as Upload } from "assets/images/icons/project-icons/upload-4.svg";
import Maybe from "../common/Maybe";
import { toast } from "react-toastify";
import { selectCustomer } from "store/entities/employees/action";
import ResetBvnModal from "./ResetBvnModal";
import RejectFaceIdModal from "./RejectFaceIdModal";
import RejectAddressVerificationModal from "./RejectAddressVerificationModal";
import { rejectCustomerAddressDoc, rejectCustomerFaceId, resetCustomerBvn, resetCustomerFaceIdStatus } from "store/entities/employees/action";
import { ManualAddressValidationForm, ManualFaceIDForm, ManualFullNameForm, StatusTimeline } from "./CustomerMiscUtils";
import FaceVerificationView from "./FaceVerificationView";
import AddressVerificationView from "./AddressVerificationView";
import BvnInformationView from "./BvnInformationView";
import UserDetailsView from "./UserDetailsView";
import ResetFaceIdModal from "./ReseFaceIdModal";

const CustomerDetailsModal = ({control, onClose, onApprove, onReject, loading, customer, selectedCustomer, fetchCustomers}) => {
	const dispatch = useDispatch();

	const [selectedComment, setSelectedComment] = useState(null);
	const [isBvnResetOpen, setIsBvnResetOpen] = useState(false);
	const [isFaceIdRejectOpen, setIsFaceIdRejectOpen] = useState(false);
	
	const [isAddressRejectionOpen, setIsAddressRejectionOpen] = useState(false);
	const { toggleCustomerKycLoading } = useSelector((state) => state.entities.employees)
	const [isFaceValidationManual, setIsFaceValidationManual] = useState(false);
	const [isAddressValidationManual, setIsAddressValidationManual] = useState(false);
	const [isNameUpdateManual, setisNameUpdateManual] = useState(false);
	const [isFaceIdResetOpen, setIsFaceIdResetOpen] = useState(false);
	
	const user = customer?.user;
	const isPendingUser = user?.kycStatus === "pending"
	
	const detailKeyClass = "text-xs"
	const detailValueClass = "mt-1 font-medium capitalize text-sm"
	const kycIsPdf = user?.kycAddressVerificationFile?.slice(-3) === "pdf" ? true : false
	const history = useHistory();
	
	const toggleBvnReset = () => setIsBvnResetOpen(!isBvnResetOpen);
	const toggleFaceIdReject = () => setIsFaceIdRejectOpen(!isFaceIdRejectOpen);
	const toggleAddressReject = () => setIsAddressRejectionOpen(!isAddressRejectionOpen);
	
	const toggleManualFaceValidation = () => setIsFaceValidationManual(!isFaceValidationManual);
	const toggleManualAddressValidation = () => setIsAddressValidationManual(!isAddressValidationManual);
	const toggleManualNameUpdate = () => setisNameUpdateManual(!isNameUpdateManual);
	
	const toggleFaceIdReset = () => setIsFaceIdResetOpen(!isFaceIdResetOpen);
	
	const displayDetails = (details) => { 
		if (loading === true) {
			return <FontAwesomeIcon icon="spinner" spin className="text-md" />;
		}
		return details || "-";
	}
	
	const openKycDocument = () => {
		const newTab = window.open();
		newTab.document.write(`<img src="${user?.kycAddressVerificationFile}" alt="Address Verification Document" />`);
	}
	
	const copyItem = (item, tag) => {
		navigator.clipboard.writeText(item);
		toast(`${tag} Copied`)
	}
	
	const viewUserTransactions = () => {
		dispatch(selectCustomer(selectedCustomer)); 
		history.push(`/users/customers/transactions/${selectedCustomer?.firstName}-${selectedCustomer?.lastName}`);
	}
	
	
	const onBvnReset = () => { 
		dispatch(resetCustomerBvn({
			userId: user?._id
		})).then(() => {
			toggleBvnReset();
			onClose();
			fetchCustomers();
		});
	}
	
	
	const onFaceIdReject = () => { 
		dispatch(rejectCustomerFaceId({
			userId: user?._id
		})).then(() => {
			toggleFaceIdReject();
			onClose();
			fetchCustomers();
		});
	}
	
	
	const onAddressReject = (reason) => {
		if (!reason) {
			toast.error("Please enter a valid reason");
			return;
		}
		dispatch(rejectCustomerAddressDoc({
			userId: user?._id,
			reason,
		}));
	}
	
	const onFaceIdReset = () => { 
		dispatch(resetCustomerFaceIdStatus({
			userId: user?._id
		})).then(() => {
			toggleFaceIdReset();
			onClose();
			fetchCustomers();
		});
	}
	
	useEffect(() => {
		if(control === false) {
			setIsFaceValidationManual(false);
			setIsAddressValidationManual(false);
			setisNameUpdateManual(false);
		}
	}, [control])
	
	return (
		<>
			
			<ResetBvnModal 
				isOpen={isBvnResetOpen}
				setIsOpen={setIsBvnResetOpen}
				onClose={toggleBvnReset}
				onProceed={onBvnReset}
				user={user}
				loading={toggleCustomerKycLoading}
			/>
			
			
			<RejectFaceIdModal
				isOpen={isFaceIdRejectOpen}
				setIsOpen={toggleFaceIdReject}
				onProceed={onFaceIdReject}
				user={user}
				loading={toggleCustomerKycLoading}
			/>
			
			
			<RejectAddressVerificationModal
				isOpen={isAddressRejectionOpen}
				toggleAddressReject={toggleAddressReject}
				onProceed={onAddressReject}
				user={user}
				loading={toggleCustomerKycLoading}
				onClose={onClose}
				fetchCustomers={fetchCustomers}
			/>
			
			
			<ResetFaceIdModal
				isOpen={isFaceIdResetOpen}
				setIsOpen={toggleFaceIdReset}
				onProceed={onFaceIdReset}
				user={user}
				loading={toggleCustomerKycLoading}
			/>
			
			
				<div
					className={
						"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
						(control ? "right-0" : "-right-full")
					}>
					<div className="w-full h-full p-5 pb-10 relative overflow-auto overscroll-contain">
						<div>
							<div className="my-8">
								
								<UserDetailsView 
									user={user}
									Upload={Upload}
									loading={loading}
									viewUserTransactions={viewUserTransactions}
									onClose={onClose}
									copyItem={copyItem}
									displayDetails={displayDetails}
									detailKeyClass={detailKeyClass}
									detailValueClass={detailValueClass}
									isNameUpdateManual={isNameUpdateManual}
									toggleManualNameUpdate={toggleManualNameUpdate}
								/>
								
								<ManualFullNameForm
									user={user}
									onCancel={toggleManualNameUpdate}
									control={isNameUpdateManual === true}
									fetchCustomers={fetchCustomers}
								/>
								
								<BvnInformationView
									user={user}
									copyItem={copyItem}
									toggleBvnReset={toggleBvnReset}
									displayDetails={displayDetails}
								/>
								
								<FaceVerificationView 
									user={user}
									Upload={Upload}
									displayDetails={displayDetails}
									detailKeyClass={detailKeyClass}
									detailValueClass={detailValueClass}
									toggleFaceIdReject={toggleFaceIdReject}
									isFaceValidationManual={isFaceValidationManual}
									toggleManualFaceValidation={toggleManualFaceValidation}
									toggleFaceIdReset={toggleFaceIdReset}
								/>
								
								<ManualFaceIDForm
									user={user}
									onCancel={toggleManualFaceValidation}
									control={isFaceValidationManual === true}
								/>
								
								<AddressVerificationView 
									user={user}
									Upload={Upload}
									kycIsPdf={kycIsPdf}
									detailKeyClass={detailKeyClass}
									PdfPlaceholder={PdfPlaceholder}
									displayDetails={displayDetails}
									openKycDocument={openKycDocument}
									detailValueClass={detailValueClass}
									toggleAddressReject={toggleAddressReject}
									isAddressValidationManual={isAddressValidationManual}
									toggleManualAddressValidation={toggleManualAddressValidation}
								/>
								
								<ManualAddressValidationForm
									user={user}
									onCancel={toggleManualAddressValidation}
									control={isAddressValidationManual === true}
								/>
								
								<Maybe condition={user?.adminKycComment?.length > 0}>
									<div className="w-full h-px my-5 bg-gray-200"></div>
									<div className="w-full flex flex-col pr-4">
										{user?.adminKycComment?.sort((a, b) => new Date(b?.timestamp) - new Date(a?.timestamp))?.map((comment) => {
											const isActive = selectedComment?._id === comment?._id;
											
											const toggleCommentView = () => isActive ? setSelectedComment(null) : setSelectedComment(comment);
											
											return (
												<StatusTimeline
													key={comment?._id}
													isActive={isActive}
													text={comment?.comment}
													email={comment?.commentBy}
													timestamp={comment?.timestamp}
													toggleCommentView={toggleCommentView}
												/>
											)
										})}
									</div>
								</Maybe>
							</div>
						</div>
					</div>
					
					
					<Maybe condition={isFaceValidationManual === false && isAddressValidationManual === false && isNameUpdateManual === false}>
						<div className="py-4 fixed bottom-0 bg-white flex justify-start" style={{ width: "25.5rem" }}>
							<Maybe condition={loading === false && isPendingUser === true}>
								<div className="flex sm:justify-end w-full">
									<button 
										type="button" 
										onClick={onReject}
										className="btn btn-md border border-red-500 text-red-500">
										Reject
									</button>
									
									<button
										type="button"
										onClick={onApprove}
										className="btn btn-md bg-ep-primary-500 text-white ml-3"
										disabled={selectedCustomer?.kycLevel !== 3}>
										Approve
									</button>
								</div>
							</Maybe>
						</div>
					</Maybe>
				</div>
		</>
		);
}


export default CustomerDetailsModal;
